import {useCallback, useEffect} from "react";
import {useConfig} from "../../providers/ConfigProvider.tsx";
import {CURRENT_ENV, ENV} from "../../../domain/core/constants.ts";

interface UsePostMessageProps {
    handleIncomingMessages?: boolean,
    onMessage?: (message: MessageEvent) => void,
    checkOrigin?: boolean
}

export const usePostMessage = (
    { handleIncomingMessages = false, onMessage, checkOrigin = true }: UsePostMessageProps = {}
) => {
    const { specifics } = useConfig();

    const listener = (message: MessageEvent) => {
        if (checkOrigin === true) {
            if (!(specifics?.postMessage?.acceptedOrigins[CURRENT_ENV] ?? []).includes(message.origin)) {
                return;
            }

            // Only log 'secured' post messages
            if (CURRENT_ENV !== ENV.Prod) {
                console.groupCollapsed('[Smartpush] Post Message received');
                console.log('event', message);
                console.log('referrer', document.referrer);
                console.groupEnd();
            }
        }

        if (onMessage) {
            try {
                const parsedJson: object = JSON.parse(message.data as string) as object;
                onMessage({
                    ...message,
                    data: parsedJson
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
            } catch (e) {
                onMessage(message);
            }
        }
    };

    useEffect(() => {
        if (handleIncomingMessages) {
            window.addEventListener("message", listener);

            return () => {
                window.removeEventListener("message", listener);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [specifics?.postMessage]);

    const sendPostMessage = useCallback((content: object|string, stringify = false) => {
        if (!specifics?.postMessage?.acceptedOrigins[CURRENT_ENV]) {
            return;
        }

        const origin = document.referrer && document.referrer.length > 0 ?
            new URL(document.referrer).origin :
            specifics?.postMessage.acceptedOrigins[CURRENT_ENV][0];

        if (specifics?.postMessage?.acceptedOrigins[CURRENT_ENV].includes(origin)) {
            if (CURRENT_ENV !== ENV.Prod) {
                console.groupCollapsed('[Smartpush] Post Message sent');
                console.log('data', content);
                console.groupEnd();
            }

            if (stringify && typeof content === 'object') {
                window.parent.postMessage(JSON.stringify(content), origin);
            } else {
                window.parent.postMessage(content, origin);
            }
        }
    }, [specifics?.postMessage]);

    const sendStatusPostMessage = useCallback((status: string, additionalContent: object = {}) => {
        sendPostMessage({
            status: status,
            ...additionalContent
        }, true);
    }, [sendPostMessage])

    return {
        sendPostMessage,
        sendStatusPostMessage
    }
}
